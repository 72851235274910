<template>
  <div>
    <h2 align="center"
        class="mt-16 pt-8 mb-4"
        style="font-size: 30px">
      {{$t('features.header_features_bold_first')}}
      <span style="font-weight: 300"> {{$t('features.header_features')}} </span>
      {{$t('features.header_features_bold')}}
    </h2>

    <v-row class="mx-auto mt-4" style="max-width:800px">
      <v-col class="text-center">
        <h3 class="font-weight-bold mb-2">{{$t('features.feature_shorts.1_header')}}</h3>
        <p class="body-2">{{$t('features.feature_shorts.1_text')}}</p>
      </v-col>
      <v-col class="text-center">
        <h3 class="font-weight-bold mb-2">{{$t('features.feature_shorts.2_header')}}</h3>
        <p class="body-2">{{$t('features.feature_shorts.2_text')}}</p>
      </v-col>
      <v-col class="text-center">
        <h3 class="font-weight-bold mb-2">{{$t('features.feature_shorts.3_header')}}</h3>
        <p class="body-2">{{$t('features.feature_shorts.3_text')}}</p>
      </v-col>
      <v-col class="text-center">
        <h3 class="font-weight-bold mb-2">{{$t('features.feature_shorts.4_header')}}</h3>
        <p class="body-2">{{$t('features.feature_shorts.4_text')}}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "FeatureList"
}
</script>

<style scoped>

</style>